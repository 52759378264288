export const ROUTES = {
    SETTINGS: '/settings',
    SIGNUP: '/signup',
    INDEX: '/',
    AFFILIATE: '/affiliate',
    FREE_8K_ULTRASOUND: '/free-8k-ultrasound',
    SUGGEST_ETHNICITY: '/suggest-ethnicity',
    STUDIO_FAQ: '/studio-faq',
    STUDIO: '/studio',
    PRIVACY: '/privacy',
    LOGIN: '/login',
    WORKSPACE: '/workspace',
    CONTEST_APPLY: (jobId: string) => `/contest/apply/${jobId}`,
    PERSONALIZE: (jobId: string) => `/personalize/${jobId}`,
    EDIT_RESULT: (resultId: string) => `/edit/${resultId}`,
    ENHANCE: (jobId: string) => `/enhance/${jobId}`,
    SHARED_RESULTS: (sharedToken: string) => `/results/${sharedToken}`,
    INVITE: (inviteCode: string) => `/invite/${inviteCode}`,
    UNLOCK: (jobId: string) => `/unlock/${jobId}`,
    REFUND: (jobId: string) => `/refund/${jobId}`,
    PREPAY: '/prepay',
    FINISH_LOGIN: (secretToken: string) => `/login/finish/${secretToken}`,
    FINISH_CREDENTIAL_CHANGE: (changeType: string, secretToken: string) => `/settings/credentials/change/${changeType}/${secretToken}`,
    EDITOR: '/editor/',
    CROP_3D_ULTRASOUND: '/crop_3d_ultrasound/',
    EIGHT_K_ULTRASOUND: '/8k-ultrasound',
    BLOG: '/blog',
    BLOG_POST: (slug: string) => `/blog/${slug}`,
} as const;
